
@font-face {
    font-family: 'Untitled Sans';
    src: url('../../public/fonts/UntitledSans-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('../../public/fonts/UntitledSans-Medium.ttf') format('truetype');
    font-display: swap;
    font-weight: 500;
}


@font-face {
    font-family: 'icomoon-jc';
    src: url('../../public/fonts/icomoon-jc.ttf') format('truetype'),
    url('../../public/fonts/icomoon-jc.woff') format('woff'),
    url('../../public/fonts/icomoon-jc.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-display: swap;
}

@font-face {
    font-family: "Rumpi";
    src: url("../../public/fonts/Rumpi.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Erotique";
    src: url("../../public/fonts/Erotique-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}

html {
    font-size: 100%;
}

body {
    font-family: 'Untitled Sans', sans-serif !important;
    font-weight: normal;
}

h1,
h2,
h3 {
    font-family: 'Untitled Sans', sans-serif;

}

h4,
h5,
h6 {
    font-family: 'Untitled Sans', sans-serif;

}

strong {
    font-family: 'Untitled Sans', sans-serif;
    font-weight: 500;
}

em {
    font-family: 'Untitled Sans', sans-serif;
    font-weight: normal;
}
