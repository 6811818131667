@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}
body {
	background-color: #ffffff !important;
	overflow: auto;
}

/* Custom class for filling a whole boostrap block */
.img-fluid-fill {
	width: 100%;
	height: auto;
	max-width: 100%;
}

@media only screen and (max-width: 991px) {
	.grecaptcha-badge {
		visibility: hidden;
	}
}

@media screen and (max-width: 767px) {
	.hiddenBelowMD {
		display: none;
	}

	.visibleBelowMD {
		display: block;
	}
}

@media screen and (min-width: 767px) {
	.visibleBelowMD {
		display: none;
	}
}

@media screen and (max-width: 575px) {
	.hiddenBelowSM {
		display: none;
	}

	.visibleBelowSM {
		display: block;
	}
}

@media screen and (min-width: 575px) {
	.visibleBelowSM {
		display: none;
	}
}

input[type='checkbox'] {
	position: relative;
	appearance: none;
	outline: none;
	margin-right: 5px;
}

input[type='checkbox']:before {
	width: 12px;
	height: 12px;
	border: 1px solid #000000;
	font-family: 'icomoon-jc';
	content: '\e907';
	margin-top: -3px;
	color: transparent;
	background: #fff;
	font-size: 13px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

input[type='checkbox']:checked:before {
	font-family: 'icomoon-jc';
	content: '\e907';
	color: #464a55;
}

/* a {
	color: #000000;
	text-decoration: underline;
}

a:hover {
	color: #000000;
	text-decoration: none;
} */

.Rockness {
	font-family: 'Rockness Regular';
}

.location-icon::before {
	content: '\e904';
	font-family: 'icomoon-jc' !important;
	margin-right: 5px;
	font-size: 14px;
}

.bold {
	font-family: 'Untitled Sans';
	font-weight: 500;
}

.section-header {
	font-size: 14px;
	text-transform: uppercase;
}

.uppercase {
	text-transform: uppercase;
}

.pointer {
	cursor: pointer;
}



.grecaptcha-badge {
	visibility: hidden;
}

img.blur{
	transition: opacity 0.1s, filter 0.4s 0.1s
}
img.pending {
	opacity: 0;
	filter: blur(15px);
}
img.loaded{
	opacity: 1;
	filter: blur(0px);
}

.menuPopup h1 {
    font-weight: inherit;
}
#trustbadge-container-98e3dadd90eb493088abdc5597a70810{
	display: none;
}
@media screen and (max-width: 768px) {
	.pauseScroll {
			overflow: hidden;
			height: 100vh;
	}
	.pauseScroll ._hj-YR-2H__Feedback__container {
		z-index: 1!important;
	}
}

@media screen and (max-width: 768px) {
	#tweakwise-guided-selling .twn-progress-bar {
		width: 330px;
		margin: 0 auto;
		border-radius: 14px;
	}
	#tweakwise-guided-selling .twn-progress-bar__fill {
		border-radius: 14px;
	}
	#tweakwise-guided-selling .twn-progress-bar__label {
		text-align: center;
		background-color: transparent;
		line-height: 14px;
		border-radius: 14px;
	}
	#tweakwise-guided-selling .twn-question__head {
		margin: 64px 0;
	}
	#tweakwise-guided-selling .twn-question__title {
		color: #FEF8EE;
		text-align: center;
		font-family: Erotique;
		font-size: 32px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	#tweakwise-guided-selling .twn-question-list-item__input {
		display: none;
	}
	#tweakwise-guided-selling .twn-question__list {
		align-items: center;
		gap: 12px;
	}
	#tweakwise-guided-selling  .twn-question-list-item {
		width: 300px;
		height: 60px;
		background: white;
		border-radius: 0;
		border: 3px solid transparent;
	}

	#tweakwise-guided-selling  .twn-question-list-item.is-selected {
		position: relative;
		border: 3px solid #5661A6;
	}
	#tweakwise-guided-selling  .twn-question-list-item.is-selected::after {
		content: "";
		position: absolute;
		background: url("/images/check-tweakwise.svg");
		width: 24px;
		height: 24px;
		right: 10px;
		top: 15px;
		color: #5661A6
	}
	#tweakwise-guided-selling  .twn-question-list-item__head {
		height: 100%;
	}
	#tweakwise-guided-selling  .twn-question-list-item__title {
		font-size: 12px;
	}
	#tweakwise-guided-selling  .twn-question__foot {
		width: 232px;
		margin: 64px auto 0 auto;
	}
	#tweakwise-guided-selling  .twn-question__foot .twn-button {
		width: 232px;
		height: 45px;
		border-radius: 0;
		color: black;
		font-family: "Untitled Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	#tweakwise-guided-selling  .twn-question__foot .twn-button.is-primary {
		background-color: #B9C0DE;
	}
}

.pdp__img-zoom .iiz__zoom-img {
	height: 2000px;
}

.iiz {
	display: block!important;
}
.iiz,
.iiz > div,
.iiz > div >img {
	height: 100%;
}
